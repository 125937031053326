<template>
  <vue-modal @close="$emit('close')" ref="modal" size="md">
    <template #title>User Deleted</template>
    <template #body>
      <FormText
        label="Nombre"
        icon="fa-solid fa-user"
        v-model="form.first_name"
        disabled
      />
      <FormText
        label="Apellido"
        icon="fa-solid fa-user"
        v-model="form.first_name"
        disabled
      />
      <FormText
        label="Email"
        icon="fa-solid fa-envelope"
        v-model="form.email"
        disabled
      />
      <FormText
       v-if="form.role != null"
        label="Role"
        icon="fa-solid fa-chess"
        v-model="form.role.role_name"
        disabled
      />
      <FormText
        label="Id Persona"
        icon="fa-solid fa-address-card"
        v-model="form.persona_id"
        disabled
      />
      <FormDate
        label="Creado"
        icon="fa-solid fa-calendar"
        v-model="form.created_at"
        disabled
      />
      <FormDate
        label="Actualizado"
        icon="fa-solid fa-calendar"
        v-model="form.updated_at"
        disabled
      />
      <FormText
        label="USER ID de quien elimino"
        icon="fa-solid fa-shield"
        v-model="form.who_deleted"
        disabled
      />
      <FormDate
        label="Eliminado"
        icon="fa-solid fa-calendar"
        v-model="form.deletedAt"
        disabled
      />
    </template>
    <template #footer>
       <button class="btn btn-primary" @click="close" type="submit">
        Cerrar
      </button>
    </template>
  </vue-modal>
</template>
<script>
import FormText from "@/components/Form/FormText.vue";
import FormDate from "@/components/Form/FormDate.vue";
export default {
  components: {
    FormText,
    FormDate
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: null,
  }),
  mounted() {
    if (this.item) {
      this.form = {...this.item};
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
<style></style>
