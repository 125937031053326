<template>
  <div class="row">
    <div class="col-12">
      <Widget class="h-auto">
        <template #title>Users Eliminados</template>
        <vue-table
          v-if="vTable.headers"
          :values="items"
          :headers="vTable.headers"
          :actions="actions"
          :options="vTable.options"
          ref="vtable"
          :empty_message="canGet ? `No hay usuarios para mostrar` : `No tienes permisos para ver usuarios eliminados`"
          @changed="getItems"
          @onShowItem="onShowItem"
          @onActiveItem="onActiveItem"
        />
        <div v-if="canGet" class="text-center mt-3"><button class="btn btn-ligh float-right" @click="goToEvents()">Usuarios Activos</button></div>
      </Widget>
    </div>  
    <UserDeletedModal
      v-if="showModal"
      @close="closeModal"
      :item="selItem"
      @saved="onSaveItem"
    /> 
  </div>
</template>

<script>
import Widget from "@/components/Widget.vue";
import UserDeletedModal from "./UserDeletedModal.vue";
import {can, canPG} from '@/acl/methods';
export default {
  components: {
    Widget,
    UserDeletedModal,
  },
  data: () => ({
    vTable: {
      headers: [
        {
          title: "id",
          mask: "ID",
          sortable: true,
        },
        {
          title: "first_name",
          mask: "Nombre",
          sortable: true,
        },
        {
          title: "last_name",
          mask: "Apellido",
          sortable: true,
        },
        {
          title: "email",
          mask: "Email",
          sortable: true,
        },
        {
          title: "role.role_name",
          mask: "Rol",
          sortable: true,
        },
        {
          title: "persona_id",
          mask: "Id Persona",
          sortable: true,
        },
      ],
      actions: [
        {
          buttonClass: "btn-info",
          callback: "onShowItem",
          tooltip: "Mostrar Detalles",
          icon: "fa-solid fa-eye",
        },
        {
          buttonClass: "btn-warning",
          callback: "onActiveItem",
          tooltip: "Activar User",
          icon: "fa-solid fa-exclamation-triangle",
        },
      ],
      options: {
        /// Go to OPTIONS SECTION for explanation
      },
    },
    showModal: false,
    selItem: null,
  }),
  mounted() {
    this.initializeTable();
  },
  computed: {
    items() {
      return this.$store.getters.noUsers;
    },
    canGet(){
      return (can(this.$store.getters.user, 'users', ['get']) && canPG(this.$store.getters.user, 'g_d_user'))
    },
    canPost(){
      return can(this.$store.getters.user, 'users', ['post'])
    },
    canPut(){
      return (can(this.$store.getters.user, 'users', ['put'] ) && canPG(this.$store.getters.user, 'p_rest_user'))
    },
    canDel(){
      return can(this.$store.getters.user, 'users', ['delete'])
    },
    actions(){
      const actions = this.vTable.actions
      if (!this.canGet) {
        actions.splice(actions.findIndex(a => a.callback === 'onShowItem'), 1)
      }
      if (!this.canPut){
        actions.splice(actions.findIndex(a => a.callback === 'onActiveItem'), 1)
      }
      return actions
    }
  },
  methods: {
    initializeTable() {
      this.$refs.vtable.init();
    },
    closeModal() {
      this.selItem = null;
      this.showModal = false;
    },
    getItems(params) {
      this.$store.dispatch("getUsersDeleted", params);
    },
    onShowItem(item) {
      this.showModal = true;
      this.selItem = item;
    },
    onSaveItem() {
      this.initializeTable(); // Reset table
    },
    onActiveItem(item) {
      this.$alert
        .fireConfirm("Activar User", "Esta seguro que desea continuar?")
        .then(() => {
          this.$store.dispatch("restoreUser", item.id).then(() => {
           this.onSaveItem();
        });
        });
    },
    goToEvents: function () {
        this.$router.push('/users')
    }
  },
};
</script>

<style>

</style>